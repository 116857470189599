header {
    width: 100%;
    background-color: black;
    height: auto;
    padding: 10px;
    border-bottom: 3px solid #FBC004;
}

.conteudo-topo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logos {
    display: flex;
    align-items: center;
}

.logo-xp {
    width: 48px;
    margin-right: 9px;
}

.logo-bene {
    width: 200px;
    margin-right: 9px;
}

.lista-navegacao {
    display: flex;
    align-items: center;
    color: white;
    padding: 10px;
}

.a {
    margin-right: 16px;
    font-size: 1.3rem;
    text-decoration: none;
    color: white;
    position: relative;
}

.a::after {
    content: " ";
    width: 0%;
    height: 2px;
    background-color: #FBC004;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.5s ease-in-out;
  }
  
.a:hover::after {
    width: 100%;
}

.botao-abra-conta button {
    margin-left: 20px;
    border: none;
    background-color: #FBC004;
    padding: 8px;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-family: 'Lekton', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: 0.6s;
}

.botao-abra-conta a {
    text-decoration: none;
    color: black;
}

.botao-abra-conta button:hover {
    background-color: antiquewhite;
    transform: scale(1.05);
}

.lista-navegacao {
    width: 55%;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 425px) {
    .botao-abra-conta {
        display: none;
    }

    .lista-navegacao {
        width: 100%;
        border-top: 1px solid white;
        justify-content: center;
    }

    .conteudo-topo {
        flex-direction: column;
    }

    .logos {
        text-align: center;
    }
  }