footer {
    width: 100%;
    background-color: black;
    padding: 10px;
    border-top: 3px solid #FBC004;
    color: white;
}

.endereco-contato-rodape {
    display: flex;
}

.endereco-rodape, .contato-rodape {
    width: 50%;
    margin: 30px 0;
}

.redes-sociais-rodape {
    margin: 30px 0;
    width: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.redes-sociais-rodape img {
    width: 40px;
}

.rodape-pagina {
    border-bottom: 1px solid white;
}

.disclaimer-rodape {
    margin: 30px 0;
    text-align: justify;
}

.copy {
    text-align: center;
    margin: 15px 0;
}

.logos-rodape {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 425px) {
    .endereco-contato-rodape {
        flex-direction: column;

    }

    .endereco-rodape, .contato-rodape {
        width: 100%;
        text-align: center;
    }

    .redes-sociais-rodape {
        justify-content: center;
        width: 100%;
    }

    footer {
        padding: 20px;
    }
  }