.secao-fale-conosco {
    padding: 80px 20px;
}

.titulo-fale-conosco {
    font-family: 'Lekton', sans-serif;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
}

.fale-conosco-mail, .fale-conosco-telefone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fale-conosco-contatos img {
    width: 35px;
    margin-right: 10px;
}

.fale-conosco-contatos a {
    text-decoration: none;
    color: #000000;
}

