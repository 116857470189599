.secao-quem-somos {
    padding: 80px 20px;
}

.titulo-pagina-quem {
    font-family: 'Lekton', sans-serif;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
}

.corpo-texto {
    font-family: 'Poppins';
    text-align: justify;
}

.corpo-texto p {
    margin-bottom: 20px;
}