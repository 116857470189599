.abrir-conta {
    padding: 80px 20px;
}

.abrir-conta h1 {
    font-family: 'Lekton', sans-serif;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
}

.identidade {
    display: flex;
    align-items: center;
    justify-content: center;
}

.identidade img {
    width: 35px;
    margin-right: 10px;
}

.clique-aqui {
    text-align: center;
    margin-top: 45px;
}

.clique-aqui a {
    color: #000000;
}