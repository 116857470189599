.secao-capa {
    display: flex;
    background-image: url('/src/assets/capapage.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    height: 65vh;
  }
  
  .titulo {
    height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    line-height: 40px;
  }

.botao button {
    margin-top: 25px;
    border: none;
    background-color: #FBC004;
    padding: 12px;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-family: 'Lekton', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: 0.6s;
    color: #000000;
}

.botao a {
    text-decoration: none;
    color: black;
}

.botao button:hover {
    background-color: antiquewhite;
    transform: scale(1.05);
}

.titulo p, .titulo h1 {
    font-size: 2.4rem;
    font-family: 'Ubuntu', sans-serif;
    line-height: 2rem;
}

.secao-porque {
    padding: 80px 20px;
    background-color: #FBC004;
}

.titulo-secao {
    font-family: 'Lekton', sans-serif;
    font-size: 3rem;
    text-align: center;
}

.cards {
    margin-top: 30px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  
.card {
    width: 30%;

    border-radius: 12px;

    padding: 40px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    transition: 1s;
  }

  .card img {
    width: 80px;
    margin-bottom: 15px;
  }

  .card h4 {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #000000;
  }
  
  .card p {
    font-size: 14.1px;
    color: #000000;
    margin-bottom: 15px;
  }

  .secao-invista {
    padding: 80px 20px;
    background-color: antiquewhite;
  }
  
  @media screen and (max-width: 425px) {
    .secao-capa {
      background-position: right 35% bottom 50%;
    }
  
  
    .cards {
      flex-direction: column;
    }
  
    .card {
      width: 100%;
    }
 
    .titulo h1 {
      font-size: 48px;
    }
  
    .titulo {
      line-height: 40px;
    }
    
    .titulo p {
      font-size: 22px;
    }
  }